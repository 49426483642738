import { EpointageThunks } from "../ducks";
import _ from "lodash";
import { Dispatch } from "react";
import { IListItem } from "../screens/Homepage";

export class SchoolViewModel {
    constructor(private dispatch: Dispatch<any>) {}

    getSchoolInfo = async (siteId: string): Promise<[]> => {
        const schoolResponse = await this.dispatch(EpointageThunks.getEpointageSchools({
            siteId: siteId || window.config.EPOINTAGE_SITEID
          }));

        return  _.get(schoolResponse, 'epointageSchools') ?? [];
    }

    getSchoolById = (id: string, schoolList: IListItem[]): IListItem => {
        return schoolList.find(item => item.id === id) || {id: '', name: ''};
    }
}
